import React from "react"
import { navigate } from "gatsby"

import { Layout } from "../../components/global/layout"
import { Quiz } from "../../components/quiz/Quiz"
import Modal from "../../components/block/Modal"
import ModalCorrect from "../../components/block/ModalCorrect"

import { questionData } from "../../data/vulva-health"
import { addQuiz3Data } from "../../state/app"
import { urls } from "../../data/urls"

export default function VulvaHealthQuizPage(props) {
  return (
    <Layout title="Vulva Health">
      <Quiz
        questionData={questionData}
        globalReducerCallback={addQuiz3Data}
        onQuizComplete={() => navigate(urls.VH.NEXT)}
        remoteKey="quiz3"
        renderIntro={(isIntroOpen, closeIntro, introFadeOut) => (
          <Modal
            isModalOpen={isIntroOpen}
            onCloseModal={closeIntro}
            fadeOut={introFadeOut}
            title="Vulva Health" //TODO Move to JSON
            text="Learn how to maintain a healthy vagina and vulva" //TODO MOVE TO JSON
            buttonText="Take the quiz" //TODO move to JSON
            backgroundColor={props => props.theme.apricot}
          />
        )}
        renderSuccess={(
          closeCorrectModal,
          correctModalIsOpen,
          correctFadeOut,
          tagSubText,
          currentResponse,
          currentQuestionIndex
        ) => {
          return (
            <ModalCorrect
              onClick={closeCorrectModal}
              modalIsOpen={correctModalIsOpen}
              fadeOut={correctFadeOut}
              closeModal={closeCorrectModal}
              emoji={false}
              title={currentResponse ? currentResponse.title : null}
              tagSubText={tagSubText}
              text={currentResponse ? currentResponse.conclusionText : ""}
              buttonText={
                currentQuestionIndex < questionData.length - 1
                  ? "Next Question"
                  : "All Finished"
              }
            />
          )
        }}
      />
    </Layout>
  )
}
