export const questionData = [
  {
    question: "Skinny jeans cause infections",
    type: "binary",
    title: "FALSE",
    conclusionText:
      "If you wear tight or synthetic clothes, this won't cause an infection but it could irritate your vulva and make your symptoms worse.",
    answers: [
      {
        text: "TRUE",
        response: "TRUE!",
      },
      {
        text: "FALSE",
        response: "FALSE!",
      },
    ],
  },
  {
    question: "If your vagina and vulva feel dry, you need to moisturise them",
    type: "binary",
    title: "FALSE",
    conclusionText:
      "It's not a good idea to apply products to your vagina or vulva, particularly if they are perfumed. They can upset your natural pH balance and make things worse. A dry vagina may be the result of your contraception (e.g. the pill), medication (e.g. antidepressants) or just plain old not being aroused during sex. If you are worried or concerned please speak to your doctor or nurse.",
    answers: [
      {
        text: "TRUE",
        response: "TRUE!",
      },
      {
        text: "FALSE",
        response: "FALSE!",
      },
    ],
  },
  {
    question: "Discharge is totally normal",
    type: "binary",
    title: "TRUE",
    conclusionText:
      "Discharge is the vagina's natural way of cleaning itself. It can be thick, watery, white, pink or brown depending on the different stages of your menstrual cycle. It can also have a faint smell. But, if your discharge is making you itch, if it smells bad or has the texture of cottage cheese, or you are worried or concerned please speak to your doctor or nurse.",
    answers: [
      {
        text: "TRUE",
        response: "TRUE!",
      },
      {
        text: "FALSE",
        response: "FALSE!",
      },
    ],
  },
  {
    // Discharge
    type: "video",
    url: "https://vimeo.com/468924297/26b35a0ae0",
  },
  {
    question:
      "Burning when you wee means you have a sexually transmitted infection (STI)",
    type: "binary",
    title: "FALSE",
    conclusionText:
      "Whilst this could be the symptom of an STI such as chlamydia, gonorrhoea or trichomoniasis, a burning sensation or pain when weeing could also be a sign of an inflammation of the bladder (cystitis), vagina (vaginitis) or urethra (urethritis). If you have not had these symptoms before or are worried or concerned please speak to your doctor or nurse.",
    answers: [
      {
        text: "TRUE",
        response: "TRUE!",
      },
      {
        text: "FALSE",
        response: "FALSE!",
      },
    ],
  },
  {
    question: "Thrush causes vaginal itch",
    type: "binary",
    title: "TRUE",
    conclusionText:
      "Itching around the vulva and/or vagina is a common symptom of thrush. You may also notice a white discharge like cottage cheese. Some STIs can also cause itchiness so it’s important to get yourself tested if you've had unprotected sex with a new sexual partner.If you are worried or concerned please speak to your doctor or nurse.",
    answers: [
      {
        text: "TRUE",
        response: "TRUE!",
      },
      {
        text: "FALSE",
        response: "FALSE!",
      },
    ],
  },
  {
    // What is thrush
    type: "video",
    url: "https://vimeo.com/468923966/8540da7b81",
  },
  {
    question: "Lumps and bumps on the vulva mean you have an STI",
    type: "binary",
    title: "FALSE",
    conclusionText:
      "Not necessarily. If you've removed pubic hair, it could be ingrowing hairs. If it's a soft, painless lump on your vulva, it could be a harmless Bartholin's cyst caused by a blocked gland. They rarely cause problems but it's always best to see your GP to rule anything more serious out. If you've had unprotected sex or sexual activity and can see small fluid-filled blisters which leave red painful sores after they burst, this could be herpes. Small, painless growths or bumps could be genital warts. If you are worried or concerned please speak to your doctor or nurse.",
    answers: [
      {
        text: "TRUE",
        response: "TRUE!",
      },
      {
        text: "FALSE",
        response: "FALSE!",
      },
    ],
  },
  {
    question: "If your vulva and vagina smell bad, you need to see a doctor",
    type: "binary",
    title: "TRUE",
    conclusionText:
      "But it depends on what's normal for you. A bad smell or a change in smell could indicate an infection. If there is a fishy smell and your discharge is thin and grey-white in colour, this could suggest BV. If it's a fishy smell with a yellow/green frothy discharge, this could suggest trichomoniasis, an STI caused by a tiny parasite. If you have not had these symptoms before or are worried or concerned please speak to your doctor or nurse.",
    answers: [
      {
        text: "TRUE",
        response: "TRUE!",
      },
      {
        text: "FALSE",
        response: "FALSE!",
      },
    ],
  },
  {
    // Bacterial Vaginosis
    type: "video",
    url: "https://vimeo.com/468923780/b242729dab",
  },
  {
    question:
      "If you've had unprotected sex but have no symptoms, you don't have an STI",
    type: "binary",
    title: "FALSE",
    conclusionText:
      "Some STIs don't have any symptoms at all, so always get an STI test after unprotected sex to be sure. Testing is quick, easy and painless and in many areas, you can get a test sent through the post to do yourself at home. If left untreated, some STIs can cause you more serious problems such as infertility. Don't risk it. If you are worried or concerned please speak to your doctor or nurse.",
    answers: [
      {
        text: "TRUE",
        response: "TRUE!",
      },
      {
        text: "FALSE",
        response: "FALSE!",
      },
    ],
  },
  {
    question: "It is normal for vaginal sex to be painful",
    type: "binary",
    title: "FALSE",
    conclusionText:
      "Umm, no... Sex should only ever feel great. If you experience pain during vaginal sex, there are a number of possible causes: not enough lubrication in the vagina, your positon during sex, the way you feel emotionally, or it could be caused by an infection. If you're worried or speak to your doctor or nurse.",
    answers: [
      {
        text: "TRUE",
        response: "TRUE!",
      },
      {
        text: "FALSE",
        response: "FALSE!",
      },
    ],
  },
  {
    // Vulva Health
    type: "video",
    url: "https://vimeo.com/468924415/9d7021fc8e",
    buttonText: "Check out the VulvaMaker!",
  },
]
